// <!-- API -->
import { ref, unref, computed } from 'vue';
import { useStore } from 'vuex';
import { HierarchyTreeNode } from './HierarchyTreeNode';
import hierarchies from '@/api/v1/accounts/hierarchies';

// <!-- TYPES -->

import { Store } from 'vuex';

// <!-- MODELS -->
// ts-ignore
/** @typedef {globalThis.Account.Model} AccountResource */
import { NoteResource } from '@/models/v1/notes/Note';
import { LocationResource } from '@/models/v1/locations/Location';
import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';

/**
 * Specialized {@link HierarchyTreeNode} collection.
 */
export class HierarchyTree {
    // #region <!-- PROTECTED MEMBERS -->

    /** @type {String} Distinct identifier given to the hierarchy input list. */
    _id = 'hierarchy';

    /** @type {Store} Reference to the Vuex store. */
    _store = useStore();

    /** @type {V.Ref<Set<'refreshing'|'initialized'>>} Status flag tracker. */
    _status = ref(new Set());

    /** @type {V.Ref<LocationHierarchyResource[]>} Location hierarchy resource nodes that correspond to the initialized state. */
    _initial = ref([]);

    /** @type {V.Ref<Map<Number, LocationHierarchyResource[]>>} Hierarchy resources mapped by depth. */
    _index = ref(new Map());

    /** @type {V.Ref<Array<LocationResource>>} Array of unassigned locations, unaffiliated with any hierarchy node. */
    _unassigned = ref([]);

    /** @type {V.Ref<HierarchyTreeNode[]>} Array of hierarchy tree nodes. */
    _nodes = ref([]);

    /** @type {V.Ref<Map<String, Function[]>>} Event callbacks for the tree. */
    _events = ref(new Map());

    // #endregion

    // #region <!-- STATIC UTILITY METHODS -->

    /**
     * Create a debug snapshot of the current index and unassigned locations.
     *
     * @param {HierarchyTree} instance Instance to snapshot.
     * @returns {{ index: [ Number, LocationHierarchyResource[] ][], unassigned: LocationResource[] }}
     */
    static snapshot(instance) {
        const index = [...instance._index.value.entries()];
        const unassigned = [...instance._unassigned.value];
        return {
            index,
            unassigned,
        };
    }

    /**
     * Unroll multiple root nodes into a flattened array of all existing resources.
     *
     * @param {LocationHierarchyResource[]} tree Tree structure containing zero or more root nodes.
     * @returns {LocationHierarchyResource[]} Node list (flattened) structure.
     */
    static flatten(tree) {
        if (!!tree && tree.length > 0) {
            return tree.flatMap((root) => root.unroll());
        }
        return [];
    }

    /**
     * Index flattened nodelist by depth.
     *
     * @param {LocationHierarchyResource[]} nodes Node list (flattened) structure to be indexed by depth.
     * @returns {Map<Number, LocationHierarchyResource[]>} Indexed map.
     */
    static index(nodes) {
        const map = new Map();
        for (const node of nodes) {
            /** @type {LocationHierarchyResource[]} Array of nodes with the same depth. */
            const array = map.get(node.depth) ?? [];
            array.push(node);
            map.set(
                node.depth,
                array.sort((a, b) => a.id - b.id)
            );
        }
        return map;
    }

    /**
     * Get preview of a location hierarchy resource.
     *
     * @param {LocationHierarchyResource} node Resource to get the preview of.
     */
    static getLocationHierarchyResourcePreview(node) {
        if (!!node) {
            return {
                id: String(node?.id),
                name: node?.name,
                depth: node?.depth,
                /** @type {(String | Number)[]} */
                children: node?.children.map((child) => child.id),
            };
        } else {
            return {
                id: null,
                name: '<Missing Resource>',
                depth: null,
                children: [],
            };
        }
    }

    /**
     * Get preview of a location resource.
     *
     * @param {LocationResource} node Resource to get the preview of.
     */
    static getLocationResourcePreview(node) {
        if (!!node) {
            return {
                id: String(node?.id),
                name: node?.name,
                depth: 5,
                children: null,
            };
        } else {
            return {
                id: null,
                name: '<Missing Location>',
                depth: 5,
                children: null,
            };
        }
    }

    /**
     * Get preview of a location hierarchy resource.
     *
     * @param {HierarchyTreeNode} node Resource to get the preview of.
     */
    static getHierarchyNodePreview(node) {
        if (node.selector !== null && node.selector.active) {
            // Create preview using the selector.
            // - Get the current value, if it exists.
            // - If valid value, get the resource.
            // - Preview the resource.
            if (!node.selector.invalid) {
                const value = node.selector.value;
                const resource = node.context.getHierarchy(value);
                return HierarchyTree.getLocationHierarchyResourcePreview(
                    resource
                );
            } else {
                return {
                    id: null,
                    name: '<Missing Resource>',
                    depth: node.selector.depth,
                    children: [],
                };
            }
        }

        if (node.textbox !== null) {
            // Create preview using the textbox.
            // - Get the new resource name, if it is valid.
            // - Preview the resource.
            if (!node.textbox.invalid) {
                const id = ''; // Empty string signals that this is a new resource.
                const name = node.textbox.value;
                const depth = node.textbox.depth;
                const children = node.hasChild
                    ? [
                          node.child.textbox.invalid
                              ? '<Invalid Hierarchy>'
                              : node.child.textbox.value,
                      ]
                    : [];
                return {
                    id,
                    name,
                    depth,
                    children,
                };
            } else {
                return {
                    id: null,
                    name: '<Invalid Hierarchy>',
                    depth: node.textbox.depth,
                    children: [],
                };
            }
        }

        if (node.locationSelector !== null) {
            // Create preview using the location selector.
            // - Get the current value, if it exists.
            // - If valid value, get the resource.
            // - Preview the resource.
            if (!node.locationSelector.invalid) {
                const resource = node.locationSelector.getSelectedLocation();
                return HierarchyTree.getLocationResourcePreview(resource);
            } else {
                return {
                    id: null,
                    name: '<Missing Location>',
                    depth: node.locationSelector.depth,
                    children: null,
                };
            }
        }
    }

    // #endregion

    // #region <!-- STATIC HELPER METHODS -->

    /**
     * Creates computed property for the current user's primary account.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<AccountResource>} Computed property.
     */
    static useComputedAccount(instance) {
        return computed(() =>
            instance.isStoreDefined
                ? instance._store.state.accounts.account
                : null
        );
    }

    /**
     * Creates computed property for the index refresh status.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<Boolean>} Computed property.
     */
    static useComputedRefreshing(instance) {
        return computed(() => instance._status.value.has('refreshing'));
    }

    /**
     * Creates computed property for the tree initialization status.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<Boolean>} Computed property.
     */
    static useComputedInitialized(instance) {
        return computed(() => instance._status.value.has('initialized'));
    }

    /**
     * Creates computed property for the hierarchy index.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<LocationHierarchyResource[]>} Computed property.
     */
    static useComputedHierarchyIndex(instance) {
        return computed(() => {
            const resources = [...instance._index.value.values()].reduce(
                // ts-ignore
                (target, nodes, depth) => {
                    const previous = target ?? [];
                    const next = nodes ?? [];
                    return [...previous, ...next];
                }
            );
            return resources.sort((a, b) => a.id - b.id);
        });
    }

    /**
     * Creates computed property for the unassigned locations not present in the hierarchy index.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<LocationResource[]>} Computed property.
     */
    static useComputedUnassignedLocations(instance) {
        return computed(() => instance._unassigned.value);
    }

    /**
     * Creates computed property for the hierarchy index roots.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<LocationHierarchyResource[]>} Computed property.
     */
    static useComputedHierarchyIndexRoots(instance) {
        return computed(() =>
            instance.isHierarchyIndexEmpty ? [] : instance._index.value.get(0)
        );
    }

    /**
     * Creates computed property for the heirarchy index leaves.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<LocationHierarchyResource[]>} Computed property.
     */
    static useComputedHierarchyIndexLeaves(instance) {
        return computed(() =>
            instance.isHierarchyIndexEmpty
                ? []
                : instance._index.value.get(instance.height.value - 1)
        );
    }

    /**
     * Prepare computed property using the passed instance.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<HierarchyTreeNode[]>} Computed property.
     */
    static useComputedTreeNodes(instance) {
        return computed(() =>
            instance.isTreeEmpty ? [] : instance._nodes.value
        );
    }

    /**
     * Creates computed property for the root tree node.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<HierarchyTreeNode>} Computed property.
     */
    static useComputedTreeRoot(instance) {
        return computed(() => !instance.isTreeEmpty && instance.nodes.value[0]);
    }

    /**
     * Creates computed property for the leaf tree node.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<HierarchyTreeNode>} Computed property.
     */
    static useComputedTreeLeaf(instance) {
        return computed(
            () =>
                !instance.isTreeEmpty &&
                instance.nodes.value[instance.height.value - 1]
        );
    }

    /**
     * Creates computed property for the maximum depth of the linear tree.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<Number>} Computed property.
     */
    static useComputedHeight(instance) {
        return computed(() => instance.nodes.value.length);
    }

    /**
     * Creates computed property for the account tree level labels.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<String[]>} Computed property.
     */
    static useComputedLabels(instance) {
        return computed(() => {
            const defaultLabels = ['* Site', '* Building', '* Floor', '* Room'];
            const accountLabels = instance.isAccountDefined
                ? instance.account.value.treeLabels?.slice(0)
                : defaultLabels.slice(0);
            return accountLabels;
        });
    }

    /**
     * Creates computed property for the initial hierarchy selection.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<LocationHierarchyResource[]>} Computed property.
     */
    static useComputedInitialHierarchy(instance) {
        return computed(() => instance._initial.value);
    }

    /**
     * Creates computed property for the current tree path.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.ComputedRef<String>} Computed property.
     */
    static useComputedHierarchyTreePath(instance) {
        return computed(() => {
            const leaf = instance.leaf.value;
            return leaf.path;
        });
    }

    /**
     * Creates computed property for the source note.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @param {NoteResource} note Note resource instance that provides the source information.
     */
    static useComputedSourceNote(instance, note) {
        return computed(() => {
            const sourceNote = note ?? new NoteResource();
            const selectedHierarchy = instance.getAncestorsAndSelf(
                String(sourceNote.hierarchyId)
            );
            const root = selectedHierarchy[0];
            const leaf = selectedHierarchy[selectedHierarchy.length - 1];
            const path = leaf?.path;
            const depth = selectedHierarchy.length;
            return {
                id: sourceNote.id,
                name: `note-${String(sourceNote.id)}`,
                path,
                depth,
                root: HierarchyTree.getLocationHierarchyResourcePreview(root),
                leaf: HierarchyTree.getLocationHierarchyResourcePreview(leaf),
                hierarchy: selectedHierarchy.map(
                    HierarchyTree.getLocationHierarchyResourcePreview
                ),
            };
        });
    }

    /**
     * Creates computed property for the source location.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @param {LocationResource} location Location resource instance that provides the source information.
     */
    static useComputedSourceLocation(instance, location) {
        return computed(() => {
            const sourceLocation = location ?? new LocationResource();
            const selectedHierarchy = instance.getAncestorsAndSelf(
                sourceLocation.hierarchyId
            );
            const root = selectedHierarchy[0];
            const leaf = selectedHierarchy[selectedHierarchy.length - 1];
            const path = leaf?.path;
            const depth = selectedHierarchy.length;
            return {
                id: sourceLocation.id,
                name: sourceLocation.name,
                path,
                depth,
                root: HierarchyTree.getLocationHierarchyResourcePreview(root),
                leaf: HierarchyTree.getLocationHierarchyResourcePreview(leaf),
                hierarchy: selectedHierarchy.map(
                    HierarchyTree.getLocationHierarchyResourcePreview
                ),
            };
        });
    }

    /**
     * Creates computed property for the clean input state, inferred from a source location.
     *
     * @param {V.Ref<ReturnType<HierarchyTree.useComputedSourceLocation>['value']> | V.Ref<ReturnType<HierarchyTree.useComputedSourceNote>['value']>} sourceResource Source note or location.
     */
    static useComputedCleanInput(sourceResource) {
        return computed(() => {
            // Extract original source state values.
            const { path, root, leaf, hierarchy } = sourceResource.value;

            // Prepare initial state results.
            const state = {
                path,
                root,
                leaf,
                hierarchy,
            };
            return state;
        });
    }

    /**
     * Creates computed property for the dirty input state, inferred from the current status.
     *
     * @param {HierarchyTree} instance Instance providing the data for the computed property.
     * @returns {V.Ref<ReturnType<HierarchyTree.useComputedCleanInput>['value'] & { valid: Boolean }>} Computed property.
     */
    static useComputedDirtyOutput(instance) {
        return computed(() => {
            // Extract dirty state values.
            const { path, root, leaf } = instance;

            // Map the nodes into the hierarchy array.
            const hierarchy = instance.nodes.value.map(
                HierarchyTree.getHierarchyNodePreview
            );

            // Prepare status object.
            const status = { isValid: true };

            // Validate dirty hierarchy.
            for (const node of hierarchy) {
                if (!status.isValid) {
                    // Break loop if already invalid.
                    break;
                }

                // Check if current node has an invalid name:
                status.isValid =
                    node.name !== '<Invalid Hierarchy>' && node.id !== null;
            }

            // Prepare dirty state results.
            const state = {
                path: path.value,
                root: HierarchyTree.getHierarchyNodePreview(root.value),
                leaf: HierarchyTree.getHierarchyNodePreview(leaf.value),
                hierarchy,
                valid: status.isValid,
            };
            return state;
        });
    }

    /**
     * Register a callback with the appropriate function map.
     *
     * @param {HierarchyTree} instance Instance to register callbacks for.
     * @param {String} event Event name.
     * @param {Function} callback Callback to invoke.
     */
    static useCallback(instance, event, callback) {
        if (!!instance && !!event && !!callback) {
            const next = callback;
            const previous = instance._events.value.get(event) ?? [];
            instance._events.value = instance._events.value.set(event, [
                ...previous,
                next,
            ]);
        }
    }

    // #endregion

    // #region <!-- CONSTRUCTOR -->

    /**
     * Creates an empty {@link HierarchyTreeNode} collection.
     */
    constructor() {
        /** @type {V.ComputedRef<AccountResource>} Current account resource. */
        this.account = HierarchyTree.useComputedAccount(this);

        /** @type {V.ComputedRef<Boolean>} Does the specified flag exist in the status array? */
        this.refreshing = HierarchyTree.useComputedRefreshing(this);

        /** @type {V.ComputedRef<Boolean>} Does the specified flag exist in the status array? */
        this.initialized = HierarchyTree.useComputedInitialized(this);

        /** @type {V.ComputedRef<LocationHierarchyResource[]>} Index of available, persisted hierarchy details from the backend. */
        this.index = HierarchyTree.useComputedHierarchyIndex(this);

        /** @type {V.ComputedRef<LocationResource[]>} Unassigned locations that are not represented by a hierarchy present in the index. */
        this.unassigned = HierarchyTree.useComputedUnassignedLocations(this);

        /** @type {V.ComputedRef<LocationHierarchyResource[]>} Root level hierarchy resources. */
        this.roots = HierarchyTree.useComputedHierarchyIndexRoots(this);

        /** @type {V.ComputedRef<LocationHierarchyResource[]>} Leaf level hierarchy resources. */
        this.leaves = HierarchyTree.useComputedHierarchyIndexLeaves(this);

        /** @type {V.ComputedRef<HierarchyTreeNode[]>} Collection of hierarchy nodes representing the linear tree data. */
        this.nodes = HierarchyTree.useComputedTreeNodes(this);

        /** @type {V.ComputedRef<HierarchyTreeNode>} First node in the tree. */
        this.root = HierarchyTree.useComputedTreeRoot(this);

        /** @type {V.ComputedRef<HierarchyTreeNode>} Last node in the tree. */
        this.leaf = HierarchyTree.useComputedTreeLeaf(this);

        /** @type {V.ComputedRef<LocationHierarchyResource[]>} Initial hierarchy, if one is present. */
        this.seed = HierarchyTree.useComputedInitialHierarchy(this);

        /** @type {V.ComputedRef<Number>} Get the maximum height of the hierarchy depth index. */
        this.height = HierarchyTree.useComputedHeight(this);

        /** @type {V.ComputedRef<String[]>} Get account specific hierarchy tree labels. */
        this.labels = HierarchyTree.useComputedLabels(this);

        /** @type {V.ComputedRef<String>} Current computed path for the hierarchy tree. */
        this.path = HierarchyTree.useComputedHierarchyTreePath(this);
    }

    // #endregion

    // #region <!-- PROPERTIES -->

    /**
     * Gets the identifier.
     */
    get id() {
        return this._id;
    }

    /**
     * Assigns identifier.
     */
    set id(value) {
        this._id = String(value).trim();
    }

    /**
     * Does the property reference exist?
     */
    get isStoreDefined() {
        return !!this._store;
    }

    /**
     * Does the property reference exist?
     */
    get isAccountDefined() {
        return !!this.account.value;
    }

    /**
     * Is an initial hierarchy selection provided?
     */
    get isInitialHierarchyDefined() {
        return !!this.seed.value && this.seed.value.length > 0;
    }

    /**
     * Is the hierarchy index empty?
     */
    get isHierarchyIndexEmpty() {
        const index = unref(this._index);
        return !index || index.size === 0;
    }

    /**
     * Does at least one unassigned location exist?
     */
    get hasUnassignedLocations() {
        return !!this._unassigned.value && this._unassigned.value.length > 0;
    }

    /**
     * Is the tree empty?
     */
    get isTreeEmpty() {
        return !this._nodes.value || this._nodes.value.length === 0;
    }

    /**
     * Does the specified status exist?
     */
    get isInitialized() {
        return this.initialized.value;
    }

    /**
     * Enable or disable the specified status.
     * @param {Boolean} value
     */
    set isInitialized(value) {
        if (value === false) {
            this._status.value.delete('initialized');
        } else {
            this._status.value.add('initialized');
        }
    }

    /**
     * Does the specified status exist?
     */
    get isRefreshing() {
        return this.refreshing.value;
    }

    /**
     * Enable or disable the specified status.
     * @param {Boolean} value
     */
    set isRefreshing(value) {
        if (value === false) {
            this._status.value.delete('refreshing');
        } else {
            this._status.value.add('refreshing');
        }
    }

    // #endregion

    // #region <!-- METHODS (VALIDATORS) -->

    /**
     * Check if a {@link LocationHierarchyResource} with a matching id exists in the index.
     *
     * @param {String | Number} id Location hierarchy id.
     */
    isHierarchyIndexResource(id) {
        // If match is found, return true.
        const ids = this.index.value.map((resource) => String(resource.id));
        return ids.includes(String(id));
    }

    /**
     * Check if a {@link LocationHierarchyResource} with a matching id exists in the index roots.
     *
     * @param {String | Number} id Location hierarchy id.
     */
    isHierarchyIndexRoot(id) {
        const roots = this.roots.value.map((resource) => String(resource.id));
        return roots.includes(String(id));
    }

    /**
     * Check if a {@link LocationHierarchyResource} with a matching id exists in the index leaves.
     *
     * @param {String | Number} id Location hierarchy id.
     */
    isHierarchyIndexLeaf(id) {
        const leaves = this.leaves.value.map((resource) => String(resource.id));
        return leaves.includes(String(id));
    }

    /**
     * Check if location resource with matching id exists in the unassigned location collection.
     *
     * @param {String | Number} id Location id.
     */
    isUnassignedLocation(id) {
        // If match is found, return true.
        return !!this.findUnassignedLocation(id);
    }

    // #endregion

    // #region <!-- METHODS (QUERIES) -->

    /**
     * Find corresponding hierarchy resource by id, if it exists in the index. Returns `null` if missing.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource}
     */
    findHierarchyIndexResource(id) {
        try {
            console.groupCollapsed(`[find::index::resource]`);
            if (this.isHierarchyIndexEmpty) {
                console.warn(
                    `No matching hierarchy index resource found for [${id}].`
                );
                return null;
            } else {
                const index = unref(this.index);
                const key = String(id);
                const resource = index.find(
                    (resource) => String(resource.id) === key
                );
                console.dir({ match: resource });
                return resource;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Find corresponding hierarchy resources by id, if they exist in the index. Returns `null` if missing.
     *
     * @param {(String | Number)[]} ids Location hierarchy id.
     * @returns {LocationHierarchyResource[]}
     */
    findHierarchyIndexResources(ids) {
        try {
            console.groupCollapsed(`[find::index::resources]`);
            if (this.isHierarchyIndexEmpty) {
                console.warn(
                    `No matching hierarchy index resources found for ${JSON.stringify(
                        ids
                    )}.`
                );
                return null;
            } else {
                const resources = ids.map(
                    this.findHierarchyIndexResource.bind(this)
                );
                console.log(`Found ${resources.length} match(es).`);
                return resources;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Find corresponding hierarchy resource by id, if it exists in the index `roots`. Returns `null` if missing.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource}
     */
    findHierarchyIndexRoot(id) {
        try {
            console.groupCollapsed(`[find::index::root]`);
            if (this.isHierarchyIndexEmpty) {
                console.warn(
                    `No matching hierarchy index root found for [${id}].`
                );
                return null;
            } else {
                const key = String(id);
                const resource = this.roots.value.find(
                    (resource) => String(resource.id) === key
                );
                console.dir({ match: resource });
                return resource;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Find corresponding hierarchy resource by id, if it exists in the index `leaves`. Returns `null` if missing.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource}
     */
    findHierarchyIndexLeaf(id) {
        try {
            console.groupCollapsed(`[find::index::leaf]`);
            if (this.isHierarchyIndexEmpty) {
                console.warn(
                    `No matching hierarchy index resource found for [${id}].`
                );
                return null;
            } else {
                const key = String(id);
                const resource = this.leaves.value.find(
                    (resource) => String(resource.id) === key
                );
                console.dir({ match: resource });
                return resource;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Find locations under the corresponding hierarchy resource (by id).
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationResource[]}
     */
    findHierarchyLocations(id) {
        try {
            console.groupCollapsed(`[find::index::locations]`);
            const resource = this.findHierarchyIndexResource(id);
            if (!resource) {
                return null;
            } else {
                const results = resource.locations;
                console.dir({ match: results });
                return results;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Find corresponding unassigned location by id, if it exists in the unassigned collection. Returns `null` if missing.
     *
     * @param {String | Number} id Location id.
     * @returns {LocationResource}
     */
    findUnassignedLocation(id) {
        try {
            console.groupCollapsed(`[find::unassigned::location]`);
            if (this.isHierarchyIndexEmpty) {
                console.warn(
                    `No matching unassigned location resource found for [${id}].`
                );
                return null;
            } else {
                const key = String(id);
                const resource = this.unassigned.value.find(
                    (resource) => String(resource.id) === key
                );
                console.dir({ match: resource });
                return resource;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Find corresponding hierarchy index resources by depth, if the depth exists in the index. Returns `null` if missing or empty.
     *
     * @param {Number} depth Index depth to retrieve.
     */
    findHierarchyIndexResourcesAtDepth(depth) {
        try {
            console.groupCollapsed(`[find::index::row]`);
            const resources = this._index.value.get(depth) ?? [];
            if (this.isHierarchyIndexEmpty || resources.length === 0) {
                console.warn(
                    `No hierarchy index resources exist at the specified depth (${depth}).`
                );
                return [];
            }
            console.dir({ match: resources });
            return resources;
        } finally {
            console.groupEnd();
        }
    }

    // #endregion

    // #region <!-- METHODS (ACCESSORS) -->

    /**
     * Get options associated with a specific node.
     *
     * @param {HierarchyTreeNode} node
     * @returns {LocationHierarchyResource[]}
     */
    getHierarchyNodeOptions(node) {
        if (!node) {
            return [];
        }

        if (node.isRoot) {
            // If root node, return the roots as the options.
            return this.roots.value;
        }

        // Get the parent.
        const parent = node.parent;
        if (!!parent && parent.selector !== null) {
            // If parent exists and has valid selector value,
            //    get the immediate children of that value.
            const value = parent.selector.value;
            if (!!value && value !== null && value !== '') {
                return this.getImmediateChildren(value);
            }
        }

        // Return an empty array if no other case has been satisfied.
        return [];
    }

    /**
     * Get selected location value of the node, if valid.
     *
     * @param {HierarchyTreeNode} node
     * @returns {LocationResource}
     */
    getHierarchyNodeLocation(node) {
        // Result wrapper.
        const result = { value: null, locations: [], match: null };

        // If node exists and location selector exists...
        if (!!node && node.locationSelector !== null) {
            const value = node.locationSelector.value;
            // If value is valid, get the location reference.
            if (!!value && value !== null && value !== '') {
                result.value = value;
                const parent = node.parent;
                if (!!parent && parent.selector !== null) {
                    // If parent is present, select matching location
                    // from collection of corresponding hierarchy.
                    const hierarchy = parent.selector.value;
                    const validHierarchy =
                        !!hierarchy && hierarchy !== null && hierarchy !== '';
                    result.locations = validHierarchy
                        ? this.findHierarchyLocations(hierarchy)
                        : [];
                } else {
                    // Else, select matching location from collection
                    // of unassigned locations.
                    result.locations = this.unassigned.value;
                }
            }
        }

        // Find match sand return if it exists.
        result.match = result.locations.find(
            (l) => String(l.id) === result.value
        );

        // Returns null when no location is selected.
        return result.match;
    }

    /**
     * Get the selected hierarchy value, if one is present and valid.
     *
     * @param {HierarchyTreeNode} node
     * @returns {LocationHierarchyResource}
     */
    getHierarchyNodeResource(node) {
        const result = { id: null, hierarchy: null };
        if (!!node && node.selector !== null) {
            const value = node.selector.value;
            if (!!value && value !== null && value !== '') {
                result.id = String(value);
            }
        }

        // Get the hierarchy resource.
        result.hierarchy = !!result.id
            ? this.findHierarchyIndexResource(result.id)
            : null;

        // Return the hierarchy.
        return result.hierarchy;
    }

    /**
     * Get the immediate parent.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource} Hierarchy resource.
     */
    getImmediateParent(id) {
        try {
            console.groupCollapsed(`[get::parent]`);
            const resource = this.findHierarchyIndexResource(id);
            return this.isHierarchyIndexRoot(resource.id)
                ? null
                : this.findHierarchyIndexResource(resource.parentId);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get the array of immediate children.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getImmediateChildren(id) {
        try {
            console.groupCollapsed(`[get::children]`);
            const resource = this.findHierarchyIndexResource(id);
            return resource.children.length === 0 ? null : resource.children;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get the array of target node and the target node's children, until terminal descendants.
     * - Target node is the first element of the array, unless it is not found.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getDescendantsAndSelf(id) {
        try {
            console.groupCollapsed(`[get::descendants] (w/ self)`);
            const node = this.findHierarchyIndexResource(id);
            return !!node ? [node, ...this.yieldDesendants(node)] : [];
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get the array of target node's children, until terminal descendants.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getDescendants(id) {
        try {
            console.groupCollapsed(`[get::descendants] (w/o self)`);
            return this.getDescendantsAndSelf(id).slice(1);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Yield all desendants (children until leaf...).
     *
     * @param {LocationHierarchyResource} node Location hierarchy resource.
     */
    *yieldDesendants(node) {
        for (const child of node.children) {
            yield child;
            if (child.children.length > 0) {
                yield* this.yieldDesendants(child);
            }
        }
        return;
    }

    /**
     * Get the array of target node and the target node's lineage, until root is found.
     * - Target node is the last element of the array, unless it is not found.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getAncestorsAndSelf(id) {
        try {
            console.groupCollapsed(`[get::ancestors] (w/ self)`);
            const node = this.findHierarchyIndexResource(id);
            const result = !!node ? [...this.yieldAncestors(node), node] : [];
            return result;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get the array of the target node's lineage, until root is found.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getAncestors(id) {
        try {
            console.groupCollapsed(`[get::ancestors] (w/o self)`);
            return this.getAncestorsAndSelf(id).slice(0, -1);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Yield all ancestors (parents until root...).
     *
     * @param {LocationHierarchyResource} node Location hierarchy resource.
     */
    *yieldAncestors(node) {
        const parent = !!node.parentId
            ? this.findHierarchyIndexResource(node.parentId)
            : null;
        if (!!parent) {
            yield* this.yieldAncestors(parent);
            yield parent;
        }
        return;
    }

    /**
     * Get the array of target node and the target node's siblings, if any.
     * - Array is sorted by id.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getSiblingsAndSelf(id) {
        try {
            console.groupCollapsed(`[get::siblings] (w/ self)`);
            const node = this.findHierarchyIndexResource(id);
            /** @type {LocationHierarchyResource[]} Siblings array. */
            const siblingsAndSelf = !!node
                ? [node, ...this.yieldAncestors(node)]
                : [];
            return siblingsAndSelf.sort((a, b) => a.id - b.id);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Get the array of the target node's siblings, if any.
     * - Array is sorted by id.
     *
     * @param {String | Number} id Location hierarchy id.
     * @returns {LocationHierarchyResource[]} Hierarchy resource.
     */
    getSiblings(id) {
        try {
            console.groupCollapsed(`[get::siblings] (w/ self)`);
            const siblings = this.getSiblingsAndSelf(id).filter(
                (node) => String(node.id) !== String(id)
            );
            return siblings;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Yield all siblings (nodes that share the same parent).
     *
     * @param {LocationHierarchyResource} node Location hierarchy resource.
     */
    *yieldSiblings(node) {
        const parent = !!node.parentId
            ? this.findHierarchyIndexResource(node.parentId)
            : null;
        if (!!parent && parent.children.length > 0) {
            yield* parent.children;
        }
        return;
    }

    // #endregion

    // #region <!-- METHODS (ACTIONS) -->

    /**
     * Invoked to initialize the context.
     */
    async init() {
        try {
            console.groupCollapsed(
                `[init::tree] @ ${new Date().toLocaleString()}`
            );

            // Request updated hierarchy index from the backend.
            await this.refreshHierarchyIndex();

            // Create initial node tree.
            this.create();

            // Invoke callbacks.
            const callbacks = this._events.value.get('onInit') ?? [];
            for (const callback of callbacks) {
                callback();
            }

            // Check if the field has been initialized.
            this.isInitialized = true;
        } catch (err) {
            console.error(err);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Fetch latest hierarchies from backend endpoint, flatten hierarchical response, and index nodes by depth.
     *
     * @returns {Promise<{ previous: ReturnType<HierarchyTree.snapshot>, next: ReturnType<HierarchyTree.snapshot> }>}
     */
    async refreshHierarchyIndex() {
        try {
            console.groupCollapsed(
                `[refresh::hierarchy::index] @ ${new Date().toLocaleString()}`
            );
            this.isRefreshing = true;

            // Request hierarchies for the current account.
            const response = await hierarchies.fetchHierarchies(
                this.account.value
            );

            // Get copy of unassigned locations.
            const unassigned = response.unassignedLocations.slice(0);

            // Get new map of hierarchy nodes mapped by depth.
            const nodes = HierarchyTree.flatten(response.hierarchies);
            const map = HierarchyTree.index(nodes);

            // Get snapshot of previous value.
            const previous = HierarchyTree.snapshot(this);

            // Assign changed values.
            this._index.value = map;
            this._unassigned.value = unassigned;

            // Get snapshot of changed values.
            const next = HierarchyTree.snapshot(this);

            // Return results.
            return { previous, next };
        } finally {
            this.isRefreshing = false;
            console.groupEnd();
        }
    }

    /**
     * Create tree using the current context state.
     *
     * @returns {HierarchyTreeNode[]} Linear node tree.
     */
    create() {
        try {
            console.groupCollapsed(
                `[create::tree] @ ${new Date().toLocaleString()}`
            );

            /** @type {HierarchyTreeNode[]} List of nodes. */
            const nodelist = [];

            /** @type {String[]} Labels to create nodes with. */
            const labels = this.labels.value;

            /** @type {HierarchyTreeContext} Context. */
            const context = new HierarchyTreeContext(this);

            /** @type {HierarchyTreeNode[]} Linear tree with parent-child relationships. */
            // ts-ignore
            const tree = labels.reduce((nodes, id, index) => {
                if (index === 0) {
                    const root = HierarchyTreeNode.root(context);
                    HierarchyTreeNode.addHierarchySelector(root);
                    HierarchyTreeNode.addTextbox(root);
                    return [root];
                } else {
                    const parent = nodes[index - 1];
                    const child = HierarchyTreeNode.create(context, parent);
                    HierarchyTreeNode.addHierarchySelector(child);
                    HierarchyTreeNode.addTextbox(child);
                    return [...nodes, child];
                }
            }, nodelist);

            // Persist the tree to the stored reference.
            this._nodes.value = tree;

            // Get any callbacks.
            const callbacks = this._events.value.get('onTreeCreated') ?? [];
            for (const callback of callbacks) {
                callback();
            }

            // Return reference (for convenience).
            return tree;
        } catch (err) {
            console.error(err);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Append a hierarchy node to the end of the tree.
     *
     * @param {HierarchyTreeNode} node Node appended to the end of the tree.
     */
    append(node) {
        try {
            console.groupCollapsed(
                `[append::tree] @ ${new Date().toLocaleString()}`
            );

            /** @type {HierarchyTreeNode[]} List of nodes. */
            const nodelist = this._nodes.value ?? [];

            /** @type {HierarchyTreeContext} Context. */
            // ts-ignore
            const context = new HierarchyTreeContext(this);

            /** @type {HierarchyTreeNode} Parent node. */
            const parent =
                nodelist.length > 0 ? nodelist[nodelist.length - 1] : null;
            if (!!parent) {
                node.parent = parent;
                parent.child = node;
            }

            // Get the updated tree.
            const tree = [...nodelist, node];

            // Persist the tree to the stored reference.
            this._nodes.value = tree;

            // Return reference (for convenience).
            return tree;
        } catch (err) {
            console.error(err);
        } finally {
            console.groupEnd();
        }
    }

    // #endregion

    // #region <!-- METHODS (EVENTS) -->

    /**
     * Register callback.
     *
     * @param {String} event
     * @param {Function} callback
     */
    on(event, callback) {
        HierarchyTree.useCallback(this, event, callback);
    }

    /**
     * Remove all callbacks for a particular event.
     *
     * @param {String} event
     */
    off(event) {
        this._events.value.set(event, []);
    }

    /**
     * Register a callback to execute just before the {@link isInitialized} flag is set to `true`.
     *
     * @param {Function} callback
     */
    onInit(callback) {
        this.on('onInit', callback);
    }

    /**
     * Register a callback immediately after the tree is created.
     *
     * @param {Function} callback
     */
    onTreeCreated(callback) {
        this.on('onTreeCreated', callback);
    }

    // #endregion
}

/**
 * Provides tailored API to the shared contextual state accessible to each individual node in a {@link HierarchyTreeNode} collection.
 */
export class HierarchyTreeContext {
    // <!-- PROTECTED MEMBERS -->

    /** @type {HierarchyTree} Underlying, internal tree reference. */
    _tree = null;

    // <!-- CONSTRUCTORS -->

    /**
     * Creates context object from a new tree.
     *
     * @param {HierarchyTree} tree
     */
    constructor(tree) {
        this._tree = tree;
    }

    // <!-- PROPERTIES -->

    get id() {
        return this._tree.id;
    }

    get isRefreshing() {
        return this._tree.isRefreshing;
    }

    // <!-- METHODS (ACCESSORS) -->

    /**
     * Get the corresponding hierarchy index resource.
     *
     * @param {String | Number} id Identifier.
     */
    getHierarchy(id) {
        return this._tree.findHierarchyIndexResource(id);
    }

    /**
     * Get the locations under the corresponding hierarchy index resource.
     *
     * @param {String | Number} id Identifier.
     */
    getLocations(id) {
        return id === null
            ? this._tree.unassigned.value
            : this._tree.findHierarchyLocations(id);
    }

    /**
     * Get the corresponding label for the tree depth provided, or `null` if none exists at that level.
     *
     * @param {Number} depth Depth to select label for.
     */
    getLabel(depth) {
        if (depth < 0 || depth >= this._tree.height.value) {
            console.warn(
                `No label found for the provided depth value (depth=${depth}).`
            );
            return null;
        }
        return unref(this._tree.labels)[depth];
    }
}

// EXPOSE
export default {
    HierarchyTree,
    HierarchyTreeContext,
};
